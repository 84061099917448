<template> 
    <Dialog header="Forgot your password?" v-model:visible="display" :modal="true" :closable="false" class='forgot-password-modal'>
        <span class='body-text'>Enter your email address and we will send you a link to reset your password (link will be active for 1 hour).</span>
        <template #footer>
            <span class='p-float-label'>
                <InputText id='forgot-email' type="text" v-model="inputValue" class='email-input'/>
                <label for="forgot-email" class='input-label'>Enter Email</label>
            </span>
            <div class='button-container'>
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
                <Button label="Submit" class='accept p-button-raised p-button-rounded' @click='submit' :loading="loading"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import UserService from '../../service/UserService';

export default {
    name: 'ForgotPasswordModal',
	data() {
		return {
			display: false,
            inputValue: null,
            loading: false,
		}
	},
	components: {
		Dialog, InputText
	},

	methods: {
        open() {
            this.display = true;
            this.inputValue = null;
            this.loading = false;
        },
		close() {
			this.display = false;
            this.inputValue = null;
            this.loading = false;
		},
        submit() {
            this.loading = true;
            this.$toast.removeAllGroups()
            
            if( this.inputValue != null && this.isValidEmail(this.inputValue.trim())) {
                UserService.requestPasswordReset(this.inputValue.trim()).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: 'An email has been sent to '+ this.inputValue.trim()+" with instructions on how to reset your password.", life: 10000, group: 'center' });
                        this.close();    
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: 'An error occurred requesting a password reset.  Please try again.  If the problem persists please contact support@invrs.com.', life: 10000, group: 'center' });
                        this.loading = false;
                    }
                });
            }
            else {
                this.$toast.add({ severity: 'error', summary: 'You have entered an invalid email address.', life: 10000, group: 'center' });
                this.loading = false;      
            }
                
        },
        isValidEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            return re.test(email);
        }
        
	}
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.forgot-password-modal {
    font-family: "Trebuchet MS", "Verdana";
    max-width: 360px;
	box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border-radius: 16px;
}

.forgot-password-modal .p-dialog-header {
    color: #32364E;
}

.forgot-password-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.forgot-password-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
}
.forgot-password-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.forgot-password-modal .p-dialog-footer {
	padding-top: 1.5rem;
}

.forgot-password-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>
* {
    font-family: 'Trebuchet MS', 'Verdana';
}

.forgot-password-modal .email-input {
    width: 100%;
    font-size: 18px;
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 15px;
}
.forgot-password-modal .email-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    margin-bottom: 1.5rem;
}
.input-label {
    font-size: 18px;
    padding-left: 15px;
    line-height: 0.8;
}

.forgot-password-modal .p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .forgot-password-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 15px;
 }

 .forgot-password-modal .p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.forgot-password-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
  .forgot-password-modal .p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.forgot-password-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
 .forgot-password-modal .p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }
</style>